import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Nav from './nav';
import { title, container } from './layout.module.scss';

import '../styles/global.scss'

const Layout = ({ pageTitle, showTitle, children }) => {

    showTitle = typeof showTitle === 'undefined' ? true : showTitle;

    const data = useStaticQuery(graphql`
      query { 
          site {
              siteMetadata {
                  title
              }
          }
      }
    `);

    return (
        <>
            <title>{pageTitle} | {data.site.siteMetadata.title}</title>
            <Nav></Nav>
            <main className={container}>
                {showTitle ? <h1 className={title}>{pageTitle}</h1> : null}
                {children}
            </main>
        </>
    )
}

export default Layout;
