import * as React from 'react';
import { Link } from 'gatsby';

import {
    overlay,
    animateIn,
    responsive,
    innerNavContainer,
    headingContainer,
    navItems,
    siteTitle,
    navBar,
    navLinks,
    navLinkItem,
    navLinkText,
    hamburgerIconContainer,
    hamburgerIcon,
    headingAndIconContainer,
} from './nav.module.scss';
import hamburgerImage from '../images/hamburger.svg';
import xSymbolImage from '../images/x-symbol.svg';

const Nav = () => {
    const [responsiveNavOpened, setResponsiveNavOpened] = React.useState(false);
    const [animationApplied, setAnimationApplied] = React.useState(false);
    const onNavClick = React.useCallback(() => {
        setResponsiveNavOpened(!responsiveNavOpened);
    });

    React.useLayoutEffect(() => {
        if (responsiveNavOpened) {
            requestAnimationFrame(() => {
                setAnimationApplied(true);
            });
        } else {
            setAnimationApplied(false);
        }
    }, [responsiveNavOpened]);

    return (
        <nav className={navBar}>
            <div className={innerNavContainer}>
                <div className={navItems}>
                    <div className={headingAndIconContainer}>
                        <div className={headingContainer}>
                            <header className={`${siteTitle} ${navLinkItem}`}>
                                <Link to="/">
                                    <span className={navLinkText}>
                                        Web Performance Tips ⚡
                                    </span>
                                </Link>
                            </header>
                        </div>
                        <button
                            className={hamburgerIconContainer}
                            onClick={onNavClick}
                            type="button"
                        >
                            <img
                                className={hamburgerIcon}
                                src={
                                    responsiveNavOpened
                                        ? xSymbolImage
                                        : hamburgerImage
                                }
                                alt="Toggle mobile navigation menu."
                            />
                        </button>
                    </div>
                    <ul
                        className={`${navLinks} ${
                            responsiveNavOpened ? responsive : ''
                        } ${animationApplied ? animateIn : ''}`}
                    >
                        <li className={navLinkItem}>
                            <Link to="/topics">
                                <span className={navLinkText}>By Topic</span>
                            </Link>
                        </li>

                        <li className={navLinkItem}>
                            <Link to="/rss.xml" target="_blank">
                                <span className={navLinkText}>RSS</span>
                            </Link>
                        </li>

                        <li className={navLinkItem}>
                            <Link to="/about">
                                <span className={navLinkText}>About</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            {responsiveNavOpened && (
                <div className={overlay} onClick={onNavClick}></div>
            )}
        </nav>
    );
};

export default Nav;
