// extracted by mini-css-extract-plugin
export var navLinks = "nav-module--nav-links--BnEsF";
export var responsive = "nav-module--responsive--mxNWQ";
export var navLinkItem = "nav-module--nav-link-item--7lxja";
export var siteTitle = "nav-module--site-title--LkZvo";
export var animateIn = "nav-module--animate-in--zVEr9";
export var navLinkText = "nav-module--nav-link-text---NyMI";
export var navBar = "nav-module--nav-bar--apNw1";
export var hamburgerIconContainer = "nav-module--hamburger-icon-container--8US8z";
export var hamburgerIcon = "nav-module--hamburger-icon--h5F+8";
export var navItems = "nav-module--nav-items--2pEH7";
export var headingContainer = "nav-module--heading-container--jF4Dl";
export var innerNavContainer = "nav-module--inner-nav-container--0kpui";
export var headingAndIconContainer = "nav-module--heading-and-icon-container--uppyz";
export var overlay = "nav-module--overlay--u-DeB";
export var fadeIn = "nav-module--fade-in--CTE8G";